.App {
  text-align: center;
  background-image: url(./assets/yadeedaColor.png);
  background-position: cover;
  background-position: left top;
  min-height: 100vh;
}

.noBackground {
  background-image: none;
}

.main {
  margin: 2rem;
}

.gridMV {
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: 2rem; 
}
